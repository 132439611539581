export default function SpectacleLogo({ size = 100 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={Math.floor(size * 1.012)}
      height={size}
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 510 500"
    >
      <g transform="translate(.56 -8.403)">
        <g>
          <g>
            <g>
              <path
                fill="#202020"
                fillRule="nonzero"
                d="M288.107 16.303l164.681 73.248c31.558 14.036 51.893 45.336 51.893 79.874v178.119c0 34.538-20.335 65.839-51.893 79.875l-164.681 73.247a87.415 87.415 0 01-71.054 0L52.371 427.419C20.814 413.383.479 382.082.479 347.544V169.425c0-34.538 20.335-65.838 51.892-79.874l164.682-73.248a87.423 87.423 0 0171.054 0z"
              />
              <path
                fill="none"
                fillRule="nonzero"
                stroke="#FC6986"
                strokeOpacity="0.5"
                strokeWidth="24"
                d="M279.696 44.792a67.128 67.128 0 00-54.404 0L64.664 115.996a57.732 57.732 0 00-34.335 52.778v179.582a57.732 57.732 0 0034.335 52.777l160.628 71.205a67.128 67.128 0 0054.404 0l160.629-71.205a57.732 57.732 0 0034.335-52.777V168.774a57.732 57.732 0 00-34.335-52.778L279.696 44.792z"
              />
              <path
                fill="none"
                fillRule="nonzero"
                stroke="#FC6986"
                strokeWidth="24"
                d="M284.007 25.519a77.337 77.337 0 00-62.855 0L56.471 98.767c-27.917 12.417-45.905 40.105-45.905 70.658v178.119c0 30.553 17.988 58.242 45.905 70.658l164.681 73.248a77.33 77.33 0 0062.855 0l164.682-73.248c27.916-12.416 45.905-40.105 45.905-70.658V169.425c0-30.553-17.989-58.241-45.905-70.658L284.007 25.519z"
              />
              <g transform="translate(109.244 121.849)">
                <g fillRule="nonzero">
                  <path
                    fill="#F14E74"
                    d="M133.25 181.944L42.592 93.853c-11.098-10.782-9.804-29.022 2.703-38.114 12.508-9.092 30.154-4.622 36.876 9.342l54.914 114.075c1.17 2.43-1.904 4.664-3.835 2.788"
                  />
                  <path
                    fill="#FC6986"
                    d="M137.08 179.135L82.067 65.108c-6.734-13.958.719-30.64 15.574-34.86 14.855-4.221 29.898 6.07 31.435 21.504l12.56 126.089c.268 2.686-3.384 3.723-4.556 1.294"
                  />
                  <path
                    fill="#E13E65"
                    d="M130.542 185.845L15.243 134.5C1.13 128.215-3.905 110.643 4.722 97.78c8.627-12.863 26.726-14.77 37.822-3.986l90.644 88.106c1.931 1.876-.19 5.038-2.646 3.945"
                  />
                  <path
                    fill="#FC6986"
                    d="M3.845 99.275c.618-1.267 5.452-10.3 18.702-12.521 6.012-1.007 14.627.963 21.824 8.901l83.456 80.96.601 3.485-82.297-64.701s-13.082-10.252-28.044-.644l-.035-.039a10.58 10.58 0 01-5.6 1.607c-5.895 0-10.674-4.802-10.674-10.724 0-2.368.773-4.55 2.067-6.324"
                  />
                  <path
                    fill="#FF92AC"
                    d="M43.711 56.892c1.011-.979 8.623-7.812 21.832-5.356 5.993 1.113 13.421 5.921 17.491 15.85l50.913 105.166-.618 3.482-55.347-89.486s-8.814-14.123-26.141-10.222l-.021-.048a10.57 10.57 0 01-5.809-.409c-5.541-2.022-8.402-8.174-6.391-13.741a10.656 10.656 0 014.091-5.236"
                  />
                  <path
                    fill="#FF92AC"
                    d="M95.897 30.914c1.284-.57 10.773-4.353 22.333 2.523 5.245 3.118 10.572 10.2 10.991 20.93l11.774 116.349-1.774 3.055-21.307-103.16s-3.438-16.308-21.042-18.639l-.003-.052a10.585 10.585 0 01-5.314-2.394c-4.509-3.814-5.088-10.579-1.292-15.111a10.602 10.602 0 015.634-3.501"
                  />
                  <path
                    fill="#FF92AC"
                    d="M127.602 45.338l-.858 111.944s3.407 25.113 7.651 30.279l-19.887-8.833c-7.512-6.39-5.54-20.646-5.54-20.646l18.634-112.744z"
                  />
                  <path
                    fill="#E13E65"
                    d="M142.425 188.83c-14.822 0-26.839-12.074-26.839-26.967l15.609-151.132C131.793 4.95 136.64.558 142.425.558c5.784 0 10.633 4.392 11.23 10.173l15.609 151.132c0 14.893-12.017 26.967-26.839 26.967"
                  />
                  <path
                    fill="#FF92AC"
                    d="M134.618 26.354l7.82 13.852 7.86-13.675"
                  />
                  <path
                    fill="#FC6986"
                    d="M153.642 11.828c0 6.225-2.366 20.199-11.217 20.199-8.48 0-11.217-13.974-11.217-20.199 0-6.224 5.022-11.27 11.217-11.27s11.217 5.046 11.217 11.27"
                  />
                  <path
                    fill="#FC6986"
                    d="M187.696 30.247c-12.972-3.685-26.086 3.696-30.226 15.904l13.929 85.017 31.87-66.06c6.735-13.958-.718-30.64-15.573-34.861"
                  />
                  <path
                    fill="#FC6986"
                    d="M142.935 169.288c-.921-.115-15.057-2.168-14.712-18.819l3.016-74.905a96.49 96.49 0 00-3.628-30.379l-12.033 116.693a6.241 6.241 0 00.063 1.71c.31 4.212 2.129 15.211 12.538 21.257 3.52 2.044 8.117 3.929 13.779 3.977l.001-.022c.09.003.177.013.267.013 5.376 0 9.734-4.378 9.734-9.78 0-5.162-3.981-9.379-9.025-9.745"
                  />
                  <path
                    fill="#FF92AC"
                    d="M118.843 75.078s1.897 11.598-.237 24.627l2.134-6.673 2.056-15.888-3.953-2.066z"
                  />
                  <path
                    fill="#FF92AC"
                    d="M195.075 34.415a10.608 10.608 0 00-5.635-3.501c-1.284-.569-10.773-4.352-22.333 2.522-3.792 2.256-7.618 6.594-9.61 12.876l6.61 40.352 3.316-16.053s3.438-16.308 21.042-18.638l.003-.053a10.585 10.585 0 005.314-2.394c4.51-3.814 5.089-10.58 1.293-15.111"
                  />
                  <path
                    fill="#F14E74"
                    d="M240.042 55.739c-12.507-9.092-30.154-4.622-36.875 9.342l-31.78 66.016 4.477 27.326s.02.132.049.371l66.833-64.94c11.097-10.783 9.803-29.023-2.704-38.115"
                  />
                  <path
                    fill="#FF92AC"
                    d="M245.717 62.127a10.66 10.66 0 00-4.09-5.235c-1.012-.979-8.624-7.813-21.833-5.356-5.993 1.114-13.421 5.921-17.49 15.85l-30.899 63.823 1.744 10.644 34.206-55.305s8.814-14.123 26.141-10.222l.021-.049c1.875.394 3.876.297 5.809-.409 5.542-2.021 8.403-8.173 6.391-13.741"
                  />
                  <path
                    fill="#E13E65"
                    d="M280.615 97.78v-.001c-8.627-12.862-26.726-14.769-37.822-3.985l-66.879 65.007c.272 2.27 1.352 14.257-5.394 20.041l99.574-44.342c14.114-6.285 19.148-23.857 10.521-36.72"
                  />
                  <path
                    fill="#FC6986"
                    d="M281.493 99.275c-.619-1.267-5.453-10.3-18.703-12.521-6.012-1.008-14.627.963-21.823 8.902l-65.055 63.132c.107.889.342 3.316.168 6.283l63.126-49.672s13.082-10.253 28.044-.644l.035-.039a10.571 10.571 0 005.601 1.607c5.894 0 10.673-4.802 10.673-10.724a10.7 10.7 0 00-2.066-6.324"
                  />
                </g>
              </g>
              <g transform="translate(111.013 374.877)">
                <g>
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.048-.166c-.007.004-.011.009-.011.015 0 .005.001.007.004.013A.293.293 0 00.308.01c.138 0 .237-.091.237-.209 0-.121-.067-.179-.208-.222-.089-.027-.13-.055-.13-.1 0-.045.045-.067.088-.067.059 0 .094.027.123.069a.02.02 0 00.017.009c.005 0 .009-.002.014-.006l.07-.058c.006-.005.008-.009.008-.013S.525-.595.521-.6a.26.26 0 00-.223-.11c-.132 0-.226.076-.226.195 0 .113.094.177.188.205.104.031.147.063.147.117 0 .051-.042.081-.096.081-.06 0-.113-.027-.152-.099C.154-.22.151-.222.144-.222a.024.024 0 00-.014.005l-.082.051z"
                    transform="scale(52)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.082-.02c0 .014.006.02.02.02h.09c.014 0 .02-.006.02-.02v-.246h.17c.146 0 .22-.096.22-.214C.602-.603.528-.7.371-.7H.102c-.014 0-.02.006-.02.02v.66zm.13-.368v-.19h.145c.078 0 .115.032.115.095s-.037.095-.115.095H.212z"
                    transform="matrix(52 0 0 52 29.224 0)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.082-.02c0 .014.006.02.02.02h.457c.014 0 .02-.006.02-.02v-.082c0-.014-.006-.02-.02-.02H.212v-.185h.267c.014 0 .02-.006.02-.02v-.082c0-.014-.006-.02-.02-.02H.212v-.149h.335c.013 0 .018-.005.018-.018V-.68c0-.014-.006-.02-.02-.02H.102c-.014 0-.02.006-.02.02v.66z"
                    transform="matrix(52 0 0 52 61.62 0)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.602-.186C.597-.193.593-.196.587-.196a.024.024 0 00-.017.008c-.042.037-.086.076-.173.076-.102 0-.215-.069-.215-.238 0-.169.116-.238.22-.238a.24.24 0 01.164.068c.005.004.009.006.013.006.006 0 .009-.003.015-.01l.054-.07C.652-.6.654-.603.654-.608c0-.006-.004-.011-.01-.016A.36.36 0 00.4-.71a.342.342 0 00-.356.36c0 .237.161.36.348.36a.35.35 0 00.259-.098C.657-.093.66-.098.66-.104.66-.109.658-.113.653-.12L.602-.186z"
                    transform="matrix(52 0 0 52 91.936 0)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.238-.02c0 .014.006.02.02.02h.09c.014 0 .02-.006.02-.02v-.558h.19c.014 0 .02-.006.02-.02V-.68c0-.014-.006-.02-.02-.02h-.51c-.014 0-.02.006-.02.02v.082c0 .014.006.02.02.02h.19v.558z"
                    transform="matrix(52 0 0 52 128.076 0)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.505-.02C.51-.007.517 0 .531 0H.63c.014 0 .02-.006.02-.02L.394-.68C.389-.693.383-.7.369-.7h-.08c-.014 0-.02.007-.025.02l-.256.66c0 .014.006.02.02.02h.099c.014 0 .021-.007.026-.02L.21-.178h.237l.058.158zM.329-.504L.403-.3H.255l.074-.204z"
                    transform="matrix(52 0 0 52 154.076 0)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.602-.186C.597-.193.593-.196.587-.196a.024.024 0 00-.017.008c-.042.037-.086.076-.173.076-.102 0-.215-.069-.215-.238 0-.169.116-.238.22-.238a.24.24 0 01.164.068c.005.004.009.006.013.006.006 0 .009-.003.015-.01l.054-.07C.652-.6.654-.603.654-.608c0-.006-.004-.011-.01-.016A.36.36 0 00.4-.71a.342.342 0 00-.356.36c0 .237.161.36.348.36a.35.35 0 00.259-.098C.657-.093.66-.098.66-.104.66-.109.658-.113.653-.12L.602-.186z"
                    transform="matrix(52 0 0 52 186.212 0)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.082-.02c0 .014.006.02.02.02h.445c.014 0 .02-.006.02-.02v-.082c0-.014-.006-.02-.02-.02H.212V-.68c0-.014-.006-.02-.02-.02h-.09c-.014 0-.02.006-.02.02v.66z"
                    transform="matrix(52 0 0 52 220.74 0)"
                  />
                  <path
                    fill="#FC6986"
                    fillRule="nonzero"
                    d="M.082-.02c0 .014.006.02.02.02h.457c.014 0 .02-.006.02-.02v-.082c0-.014-.006-.02-.02-.02H.212v-.185h.267c.014 0 .02-.006.02-.02v-.082c0-.014-.006-.02-.02-.02H.212v-.149h.335c.013 0 .018-.005.018-.018V-.68c0-.014-.006-.02-.02-.02H.102c-.014 0-.02.006-.02.02v.66z"
                    transform="matrix(52 0 0 52 250.9 0)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
